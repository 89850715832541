import Work from "./Work"
import Services from "./Services"
// import Our_Team from "./OurTeam"

function Home (){
    return (
        <>
    <Work></Work>
    <Services></Services>
    {/* <Our_Team></Our_Team> */}
        </>
    )
}

export default Home